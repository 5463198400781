[data-component="country-picker"] {

  position: relative;

  .selected-country-flag {

    overflow: hidden;
    position: relative;
    font-size: 1.8rem;
    outline: none;

    display: flex;
  }

  .selected-country-flag {
    cursor: pointer;
  }

  > .country-list {

    z-index: 10;
    min-width: 200px;

    position: absolute;
    top: 1.5rem;

    background-color: white;

    display: flex;
    flex-direction: column;

    line-height: 2;
    border-radius: var(--border-radius-medium);
    box-shadow: var(--box-shadow-sharp);

    max-height: 200px;
    overflow-y: auto;

    .country-item {

      display: flex;
      gap: 5px;
      padding: 0 5px;

      &[data-selected="true"] {
        font-weight: bold;
        background: var(--color-gray-100);
      }

      &:hover {
        background-color: var(--color-gray-50);
      }
    }
  }
}
