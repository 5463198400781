[data-component="OnboardingTest"] {
  position: fixed;
  bottom: 1rem;
  right: 1rem;

  width: 15rem;

  display: flex;
  flex-direction: column;

  padding: 1rem;
  background-color: #f1f1f1;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  gap: .5rem;
  border: 1px solid #ddd;
  border-radius: 10px;

  z-index: 5;

  > div > label {
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .investment-type {

    display: flex;

    > label{
      flex: 1;
      font-size: 12px;
      padding-right: 3px;
      display: flex;
      align-items: center;

      > input {
        margin-right: 3px;
        width: 15px;
        height: 15px;
      }
    }
  }

  .buttons {
    gap: .5rem;
    display: flex;

    .button {
      flex: 1;
    }
  }
}
