[data-page="lendo-thanks"] {


  display: flex;
  flex-direction: column;

  max-width: 32rem;
  margin: 0 auto;

  padding-top: 3rem;

  text-align: center;

  @media(max-width: 640px) {
    max-width: unset;
  }

  > p {
    color: var(--color-gray-600);
  }

  > * + * {
    margin-top: 1rem;
  }

  [data-component="feedback-bar"] > p {
    text-align: initial;
  }
}
