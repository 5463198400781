[data-component="select-companybox"] {
  padding: var(--spacing-200);

  display: flex;
  flex-direction: column;
  gap: .5rem;
  border-radius: var(--border-radius-small);
  background-color: white;
  border: 2px solid var(--color-primary-200);
  cursor: pointer;
  transition: all .3s ease;

  &:hover {
    background-color: var(--color-gray-50);
  }

  &[data-is-current="true"] {
    background-color: var(--color-primary-50);
  }

  &[data-selected="true"] {
    border: 2px solid var(--color-primary-500);
    background-color: var(--color-gray-50);
  }

  > h3 {
    font-weight: 600;
  }

  > .company-id {
    font-weight: nornal;
  }
}
