[data-page="connect-erp-index"] {

  max-width: 32rem;
  margin: 0 auto;

  @media(max-width: 640px) {
    max-width: unset;
  }

  > p {
    color: var(--color-gray-600);
  }

  .button.skip {
    display: inline-flex;
  }
}
