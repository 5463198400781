[data-component="plate"] {

  position: relative;

  &[data-variant="regular"] {
    background-color: var(--color-white);
    border: var(--color-gray-50);
  }

  &[data-variant="primary"] {
    background-color: var(--color-primary-50);
    border: var(--color-primary-100);
  }

  > .inner-wrapper {

    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    padding: var(--spacing-200);

    transition: padding .3s ease;

    border-radius: var(--border-radius-medium);
    box-shadow: var(--box-shadow-sharp);


    [data-component="feedback-bar"] {
      margin-bottom: var(--spacing-300);
    }
  }
}
