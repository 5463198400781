[data-page="cashflow"] {

  .visx-tooltip > div {
    margin: var(--spacing-100) 0;
  }

  .visx-tooltip svg {
    display: inline-block;
  }
}
