[data-component="ApplicationFormGuarantors"] {

  .button-wrapper {
    padding-top: var(--spacing-100);
    display: flex;
  }

  .guarantor-add-options {
    display: flex;
  }

  .no-items-placeholder {
    font-style: italic;
    color: var(--color-gray-500);
    display: none;

    padding: var(--spacing-100) 0;
  }

  [data-component="GuarantorsList"]:empty {
    + .no-items-placeholder {
      display: flex;
    }
  }

  > h4 {
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: var(--color-primary-500);
    margin-bottom: var(--spacing-100);
  }

  > hr {
    margin: var(--spacing-100) 0 var(--spacing-200) 0;
  }

  > .create-loader {
    height: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
